import { createWebHistory, createRouter } from "vue-router";

import HomePage from "./components/HomePage.vue";
import LampsPage from "./components/LampsPage.vue";
import AboutPage from "./components/AboutPage.vue";
import ContactPage from "./components/ContactPage.vue";
import OrderPage from "./components/OrderPage.vue"; 
import Frequent from "./components/FreQuestions.vue"

// product details

import ProductI from "./components/ProductI.vue";
import ProductIi from "./components/ProductIi.vue";
import ProductIii from "./components/ProductIii.vue";
import ProductIv from "./components/ProductIv.vue";
import ProductV from "./components/ProductV.vue";
import ProductVi from "./components/ProductVi.vue";
import ProductVii from "./components/ProductVii.vue";
import ProductViii from "./components/ProductViii.vue";
import ProductIx from "./components/ProductIx.vue";
import ProductX from "./components/ProductX.vue";
import ProductXi from "./components/ProductXi.vue";
import ProductXii from "./components/ProductXii.vue";
import ProductXiii from "./components/ProductXiii.vue";
import ProductXiv from "./components/ProductXiv.vue";
import ProductXv from "./components/ProductXv.vue";
import ProductXvi from "./components/ProductXvi.vue";
import ProductXvii from "./components/ProductXvii.vue";
import ProductXviii from "./components/ProductXviii.vue";
import ProductXix from "./components/ProductXix.vue";
import ProductXx from "./components/ProductXx.vue";


// Sections

import PendantPage from "./components/PendantPage.vue";
import FloorPage from "./components/FloorPage.vue";
import WallPage from "./components/WallPage.vue";
import TablePage from "./components/TablePage.vue";

const routes = [
    {
      name: "HomePage",
      path: "/",
      component: HomePage
    },
    {
      name: "LampsPage",
      path: "/products",
      component: LampsPage
    },
    {
      name: "AboutPage",
      path: "/about",
      component: AboutPage
    },
    {
      name: 'ContactPage',
      path: "/contact",
      component: ContactPage
    },
    {
      name: "FreQuestions",
      path: "/FAQ",
      component: Frequent
    },
    {
      name: 'OrderPage',
      path: "/order",
      component: OrderPage
    },

    // product details


    {
      name: 'ProductI',
      path: "/i",
      component: ProductI
    },
    {
      name: 'ProductIi',
      path: "/ii",
      component: ProductIi
    },
    {
      name: 'ProductIii',
      path: "/iii",
      component: ProductIii
    },
    {
      name: 'ProductIv',
      path: "/iv",
      component: ProductIv
    },
    {
      name: 'ProductV',
      path: "/v",
      component: ProductV
    },
    {
      name: 'ProductVi',
      path: "/vi",
      component: ProductVi
    },
    {
      name: 'ProductVii',
      path: "/vii",
      component: ProductVii
    },
    {
      name: 'ProductViii',
      path: "/viii",
      component: ProductViii
    },
    {
      name: 'ProductIx',
      path: "/ix",
      component: ProductIx
    },
    {
      name: 'ProductX',
      path: "/x",
      component: ProductX
    },
    {
      name: 'ProductXi',
      path: "/xi",
      component: ProductXi
    },
    {
      name: 'ProductXii',
      path: "/xii",
      component: ProductXii
    },
    {
      name: 'ProductXiii',
      path: "/xiii",
      component: ProductXiii
    },
    {
      name: 'ProductXiv',
      path: "/xiv",
      component: ProductXiv
    },
    {
      name: 'ProductXv',
      path: "/xv",
      component: ProductXv
    },
    {
      name: 'ProductXvi',
      path: "/xvi",
      component: ProductXvi
    },
    {
      name: 'ProductXvii',
      path: "/xvii",
      component: ProductXvii
    },
    {
      name: 'ProductXviii',
      path: "/xviii",
      component: ProductXviii
    },
    {
      name: 'ProductXix',
      path: "/xix",
      component: ProductXix
    },
    {
      name: 'ProductXx',
      path: "/xx",
      component: ProductXx
    },
    


    // Sections

    {
      name: 'PendantPage',
      path: "/pendant",
      component: PendantPage
    },
    {
      name: 'FloorPage',
      path: "/floor",
      component: FloorPage
    },
    {
      name: 'WallPage',
      path: "/wall",
      component: WallPage
    },
    {
      name: 'TablePage',
      path: "/table",
      component: TablePage
    },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;