<template>
  <br><br><br><br><br><br>
  <div class="order-form-container">
    <h2>Order Details</h2>

    <form @submit.prevent="submitOrder">
      <!-- Customer Name -->
      <div class="form-group">
        <label for="name">Full Name:</label>
        <input type="text" v-model="form.name" id="name" required>
      </div>

      <!-- Email -->
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" v-model="form.email" id="email" required>
      </div>

      <!-- Phone Number -->
      <div class="form-group">
        <label for="phone">Phone Number:</label>
        <input type="tel" v-model="form.phone" id="phone" required>
      </div>

      <!-- Shipping Address -->
      <div class="form-group">
        <label for="address">Shipping Address:</label>
        <textarea v-model="form.address" id="address" required></textarea>
      </div>

      <!-- Submit Button -->
      <button type="submit" class="btn submit-btn">Submit Order</button>
    </form>
  </div>
  <br><br><br><br><br><br>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        address: ''
      }
    };
  },
  methods: {
    submitOrder() {
      // For now, just logging the form details
      console.log("Order Details Submitted:", this.form);

      // You can replace this with real form submission logic (e.g., API call)
      alert('Order Submitted Successfully!');

      // Clear the form after submission
      this.form.name = '';
      this.form.email = '';
      this.form.phone = '';
      this.form.address = '';
    }
  }
};
</script>

<style scoped>
.order-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  padding-right: 40px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.139);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  height: 100px;
}

.btn.submit-btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #ff5722;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 20px;
  transition: 0.3s ease-in-out;
}

.btn.submit-btn:hover {
  background-color: white;
  color:green;
  border: 1px solid #2b2b2b5a ;

}
</style>
