<template>
  <div class="faq-page">
    <h1>Frequently Asked Questions</h1>

    <div class="faq-container">
      <div class="faq-item" v-for="(faq, index) in faqs" :key="index">
        <div class="faq-header" @click="toggleFAQ(index)">
          <img :src="faq.icon" alt="FA" class="faq-icon" />
          <h3 class="faq-question">
            {{ faq.question }}
            <span class="faq-toggle-icon">
                <img :src="faq.open ? require('@/assets/arrow.png') : require('@/assets/arrows.png')" alt="Toggle Arrow" />
            </span>
          </h3>
        </div>
        <transition name="slide-fade">
          <p v-if="faq.open" class="faq-answer">{{ faq.answer }}</p>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faqs: [
        {
          question: "What is the shipping time?",
          answer: "Shipping takes 3-5 business days within the country and 7-10 business days for international orders.",
          open: false,
          icon: require('@/assets/delivery-truck.png')

        },
        {
          question: "Do you offer refunds?",
          answer: "Yes, we offer a 30-day refund policy on all orders as long as the product is unopened and undamaged.",
          open: false,
          icon: require('@/assets/refund.png')
        },
        {
          question: "How can I track my order?",
          answer: "You will receive a tracking link via email once your order has been dispatched.",
          open: false,
          icon: require('@/assets/track.png')
        },
        {
          question: "Can I change my order after placing it?",
          answer: "Yes, you can modify your order within 24 hours of placing it by contacting our customer service.",
          open: false,
          icon: require('@/assets/editing.png')
        }
      ]
    };
  },
  methods: {
    toggleFAQ(index) {
      this.faqs[index].open = !this.faqs[index].open;
    }
  }
};
</script>

<style scoped>
.faq-page {
  background-color: #f4f7f6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
  padding-top: 65px;
}

h1 {
  font-size: 2.8rem;
  color: green;
  margin-bottom: 40px;
}

.faq-container {
  width: 100%;
  max-width: 900px;
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.faq-item {
  margin-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 20px;
}

.faq-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-header:hover {
  background-color: #f9f9f9;
}

.faq-icon {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.faq-question {
  flex-grow: 1;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-toggle-icon img {
  width: 24px;
  height: 24px;
}

.faq-answer {
  font-size: 1.2rem;
  color: #555;
  margin-top: 10px;
  text-align: left;
  padding-left: 60px;
  transition: all 0.3s ease;
  border-left: 3px solid green ;
  border-right: 3px solid #ff5722 ;
  background-color: #efeeeeb3;
    padding-top: 10px;
padding-bottom: 10px    ;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.slide-fade-enter-active {
  animation: fade-in 0.3s ease;
}

.slide-fade-leave-active {
  animation: fade-out 0.3s ease;
}
</style>
