<template>
    <div class="home">
      <!-- Hero Section -->
      <section class="hero">
        <div class="hero-text">
          <h1>Welcome to DPS Peppers</h1>
          <p>Your ultimate destination for the finest, freshest, and hottest peppers.</p> <br>
          <router-link to="/products" class="shop-now-button">Shop Now</router-link>
        </div>
      </section>
  
      <!-- Featured Peppers -->
      <section class="featured">
        <h2>Popular Peppers</h2>
        <div class="popular-peppers">
          <!-- Example peppers (replaced by dynamic data in real site) -->
          <router-link to="/i" class="links">
                <div class="card">
                    <img src="../assets/Shombo.jpg" alt="Shombo">
                    <div class="lamp-details">
                        <h3 class="lamp-title">SHOMBO - 1KG</h3>
                        <p class="lamp-description"></p>
                        <p class="lamp-price">₦4,000</p>
                    </div>
                </div>
            </router-link>
            <router-link to="/iv" class="links">
                <div class="card" >
                    <img src="../assets/Tat.jpg" alt="lamp" class="Tatashe" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">TATASHE -1KG</h3>
                        <p class="lamp-description"></p>
                        <p class="lamp-price">₦3,500</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/vi" class="links">
                <div class="card" >
                    <img src="../assets/Tom.jpg" alt="Tomato" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">TOMATO - 1KG</h3>
                        <p class="lamp-description"></p>
                        <p class="lamp-price">₦3,000</p>
                    </div>
                </div>
            </router-link>


            <router-link to="/xii" class="links">
                <div class="card" >
                    <img src="../assets/Rodo.jpg" alt="rodo" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">RODO - 1KG</h3>
                        <p class="lamp-description"></p>
                        <p class="lamp-price">₦4,000</p>
                    </div>
                </div>
            </router-link>
        </div>
      </section>
  
      <!-- Our Commitment Section -->
      <div class="about-commitment-section">
    <!-- Our Commitment Section -->
    <section class="commitment">
      <div class="commitment-content">
        <div class="text-container">
          <h2>Our Commitment to You</h2>
          <p>
            At DPS, our dedication to you is as strong as the heat in our hottest peppers.
            We’re passionate about delivering not just the best peppers, but an exceptional experience that’s tailored to your taste.
          </p>
          <p>
            Whether you're a seasoned spice lover or just beginning to explore the world of peppers,
            we're here to ensure you get exactly what you need. Your pepper journey is our priority!
          </p>
        </div>
        <div class="image-container">
          <img src="../assets/pepper-hand.webp" alt="Person holding fresh peppers" />
        </div>
      </div>
    </section>

    <!-- About Us Section -->
    <section class="about-us">
      <div class="about-content">
        <div class="image-container">
          <img src="../assets/farm-pepperss.jpg" alt="Pepper farm" />
        </div>
        <div class="text-container">
          <h2>About Us</h2>
          <p>
            DPS is committed to bringing the freshest and most flavorful peppers to your table.
            With a passion for spice, we source high-quality peppers from trusted farms, ensuring every pepper is perfect.
          </p>
          <p>
            From farm to your kitchen, our mission is to elevate your culinary experience
            with a variety of peppers that add heat and depth to your favorite dishes.
          </p>
        </div>
      </div>
    </section>
  </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "HomePage",
  };
  </script>
  
  <style scoped>
  .home {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  }
  
  .hero {
    background-image: url('../assets/background');
    background-size: cover;
    background-position: center;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .hero-text {
    text-align: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 30px;
    padding-bottom: 45px;

  }
  
  .shop-now-button {
    background-color: #ff5722;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 20px;
    border-radius: 5px;
    transition: 0.3s;
  }

  .shop-now-button:hover{
    background-color:white;
    color: #06ae06;
  }
  
  .featured {
    text-align: center;
    padding: 50px 0;
  }
  
  .popular-peppers {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .pepper-card {
    width: 200px;
    text-align: center;
  }
  
  .pepper-card img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .commitment, .contact {
    text-align: center;
    padding: 50px 0;
  }
  
  .learn-more-button, .contact-button {
    background-color: #ff5722;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 5px;
  }
  

  .about-commitment-section {
  padding: 50px 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.commitment, .about-us {
  margin-bottom: 50px;
}

.commitment-content, .about-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.text-container {
  flex: 1;
  margin-right: 20px;
}

.image-container {
  flex: 1;
  text-align: center;
}

.image-container img {
  width: 100%;
  height: auto;
  max-width: 500px;
  border-radius: 8px;
}

h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #ff5722;
}

p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .commitment-content, .about-content {
    flex-direction: column;
  }

  .text-container {
    margin-right: 0;
    text-align: center;
  }

  .image-container {
    margin-top: 20px;
  }
}

.card {
    width: 250px; 
    margin: 15px; 
    background-color: #ffffff; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
    text-align: center;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
    border: 1px solid rgb(217, 217, 217);
    border-radius: 10px;
}

.card:hover {
    transform: scale(1.05); 
}

.card img {
    width: 250px;
    height: 250px; 
    object-fit: cover; 
    border-radius: 10px;
}
  
  
.lamp-details {
    color: black;
    text-align: left;
    padding: 10px;
}
  
.lamp-title {
    font-size: 20px;
    margin-bottom: 5px; 
    font-weight: 700;
}
  
.lamp-description {
    font-size: 14px; 
    margin-bottom: 10px; 
}
  
.lamp-price {
    font-weight: bold; 
    margin-bottom: 5px; 
    font-size: 20px;
}

.links {
    text-decoration: none;
}
  </style>
  