<template>
    <div class="product-page">
      <div class="product-container">
        <img src="../assets/Tatbas.jpg" alt="Tatashe Pepper" class="product-image">
        
        <div class="product-details">
          <h1 class="product-name">TATASHE - FULL BASKET</h1>
          <p class="product-price">₦{{ price.toLocaleString() }}</p>
  
          <div class="quantity-control">
            <button @click="decreaseQuantity" class="btn-quantity">-</button>
            <input type="number" v-model="quantity" readonly>
            <button @click="increaseQuantity" class="btn-quantity">+</button>
          </div>
          <p class="total-price">Total: ₦{{ totalPrice.toLocaleString() }}</p>
  
          <router-link to="/order">
            <button class="btn order-now">Order Now</button>
          </router-link>
  
          <ul class="product-features">
            <li>Free global shipping on all orders</li>
            <li>30 days easy returns if you change your mind</li>
            <li>Order before noon for same day dispatch</li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        price: 36500,  // Price per unit of the product
        quantity: 1   // Default quantity
      };
    },
    computed: {
      // Calculate the total price based on the selected quantity
      totalPrice() {
        return this.price * this.quantity;
      }
    },
    methods: {
      // Increase the quantity
      increaseQuantity() {
        this.quantity += 1;
      },
      // Decrease the quantity, ensuring it doesn't go below 1
      decreaseQuantity() {
        if (this.quantity > 1) {
          this.quantity -= 1;
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .product-page {
    display: flex;
    justify-content: center;
    padding: 20px;
    margin-top: 80px;
  }
  
  .product-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    border: 1px solid #ddd;
    padding: 30px;
    margin-top: 10px;
    margin-bottom: 35px;
    background-color: #fffdfd90;
    border-radius: 10px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.192);
  }
  
  .product-image {
    width: 100%;
    max-width: 450px;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .product-details {
    flex: 1;
    padding-left: 20px;
    text-align: left;
  }
  
  .product-name {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .product-price,
  .total-price {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .quantity-control {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .btn-quantity {
    width: 30px;
    height: 30px;
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    border-radius: 5px;
  }
  
  input[type="number"] {
    width: 50px;
    text-align: center;
    font-size: 1.2rem;
    margin: 0 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .btn.order-now {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .btn.order-now:hover {
    background-color: #218838;
  }
  
  .product-features {
    margin-top: 30px;
    padding-left: 20px;
  }
  
  .product-features li {
    margin-bottom: 10px;
    color: #555;
    list-style: disc;
  }
  
  @media (max-width: 768px) {
    .product-container {
      flex-direction: column;
      text-align: center;
    }
  
    .product-details {
      padding-left: 0;
    }
  
    .product-name {
      font-size: 1.75rem;
    }
  
    .product-price,
    .total-price {
      font-size: 1.25rem;
    }
  
    .btn.order-now {
      width: 100%;
    }
  }
  </style>
  