import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'
import '@fortawesome/fontawesome-free/css/all.css'

createApp(App).use(router).mount('#app')

const link = document.createElement('Link');
link.rel = 'stylesheet';
link.href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css";
document.head.appendChild(link);

// font-family
link.href = "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap";

const style = document.createElement('style');
style.textContent = `
  body {
    margin: 0;
  }
`;
document.head.appendChild(style);