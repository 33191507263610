<template>
    <div class="lamps-header">
  <!-- Hero Section -->
  <section class="hero">
    <div class="hero-text">
      <h1>PEPPERS AVAILABLE</h1>
    </div>
  </section>
</div>

<!-- Write-up Section -->
<div class="write-up">
  <p>
    Welcome to DPS Peppers: Your Ultimate Source for Fresh, Flavorful Peppers.
    At DPS Peppers, we believe that the secret to an unforgettable dish lies in the quality
    of its ingredients—and nothing adds flavor and excitement like the perfect pepper.
    Whether you’re looking for a mild kick or intense heat, our diverse selection of
    premium peppers will elevate your cooking experience to new heights.
  </p>
</div>

<!-- Products Section -->
<div class="popular">
  <h1 class="popular-title">Our Available Products</h1>
        <div class="popular-lamp">

            <router-link to="/i" class="links">
                <div class="card">
                    <img src="../assets/Shombo.jpg" alt="Shombo">
                    <div class="lamp-details">
                        <h3 class="lamp-title">SHOMBO - 1KG</h3>
                        <p class="lamp-description"></p>
                        <p class="lamp-price">₦4,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/ii" class="links">
                <div class="card" >
                    <img src="../assets/shombobas.jpg" alt="Shombo" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">SHOMBO - BASKET</h3>
                        <p class="lamp-description"></p>
                        <p class="lamp-price">₦40,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/iii" class="links">
                <div class="card" >
                    <img src="../assets/shombopai.jpg" alt="Shombo" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">SHOMBO - 1 PAINT</h3>
                        <p class="lamp-description"></p>
                        <p class="lamp-price">₦15,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/iv" class="links">
                <div class="card" >
                    <img src="../assets/Tat.jpg" alt="Tatashe" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">TATASHE -1KG</h3>
                        <p class="lamp-description"></p>
                        <p class="lamp-price">₦3,500</p>
                    </div>
                </div>
            </router-link>

        </div>

        <div class="popular-lamp">

            <router-link to="/v" class="links">
                <div class="card" >
                <img src="../assets/Tatbas.jpg" alt="Tatashe" class="lamps" >
                <div class="lamp-details">
                    <h3 class="lamp-title">TATASHE - FULL BASKET</h3>
                    <p class="lamp-description"></p>
                    <p class="lamp-price">₦36,500</p>
                </div>
            </div>
            </router-link>
            
            <router-link to="/vi" class="links">
                <div class="card" >
                    <img src="../assets/Tom.jpg" alt="Tomato" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">TOMATO - 1KG</h3>
                        <p class="lamp-description"></p>
                        <p class="lamp-price">₦3,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/vii" class="links">
                <div class="card" >
                    <img src="../assets/Tombas.jpg" alt="Tomato" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">TOMATO - FULL BASKET</h3>
                        <p class="lamp-description"></p>
                        <p class="lamp-price">₦36,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/viii" class="links">
                <div class="card" >
                    <img src="../assets/Tompai.jpg" alt="Tomato" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">TOMATO - 1 PAINT </h3>
                        <p class="lamp-description"></p>
                        <p class="lamp-price">₦9,500</p>
                    </div>
                </div>
            </router-link>
            
            
        </div>
        <div class="popular-lamp">

            <router-link to="/xii" class="links">
                <div class="card" >
                    <img src="../assets/Rodo.jpg" alt="rodo" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">RODO - 1KG</h3>
                        <p class="lamp-description"></p>
                        <p class="lamp-price">₦4,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/xiii" class="links">
                <div class="card" >
                    <img src="../assets/Rodopai.jpg" alt="rodo" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">RODO - 1 PAINT</h3>
                        <p class="lamp-description"></p>
                        <p class="lamp-price">₦13,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/Xiv" class="links">
                <div class="card" >
                    <img src="../assets/rodobas.jpg" alt="rodo" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">RODO - FULL BASKET</h3>
                        <p class="lamp-description"></p>
                        <p class="lamp-price">₦33,000</p>
                    </div>
                </div>
            </router-link>
            
            <router-link to="/xvii" class="links">
                <div class="card" >
                    <img src="../assets/Drychilli.jpg" alt="chilli" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">DRY CHILLI - 1KG </h3>
                        <p class="lamp-description"></p>
                        <p class="lamp-price">₦4,500</p>
                    </div>
                </div>
            </router-link>

        </div>

        <div class="popular-lamp">

            <router-link to="/X" class="links">
                <div class="card" >
                    <img src="../assets/Onionbas.jpg" alt="Onion" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">ONION - FULL BASKET </h3>
                        <p class="lamp-description"></p>
                        <p class="lamp-price">₦15,000</p>
                    </div>
                </div>
            </router-link>

             <router-link to="/xi" class="links">
                <div class="card">
                    <img src="../assets/Onionpai.jpg" alt="onion" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">ONION - 1 PAINT</h3>
                        <p class="lamp-description"></p>
                        <p class="lamp-price">₦5,000</p>
                    </div>
                </div>
            </router-link>

             <router-link to="/ix" class="links">
                <div class="card" >
                    <img src="../assets/Tatpai.jpg" alt="Tatashe" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">TATASHE - 1 PAINT </h3>
                        <p class="lamp-description"></p>
                        <p class="lamp-price">₦10,500</p>
                    </div>
                </div>
            </router-link>

             <router-link to="/v" class="links">
                <div class="card" >
                <img src="../assets/Tatbas.jpg" alt="Tatashe" class="lamps" >
                <div class="lamp-details">
                    <h3 class="lamp-title">TATASHE - FULL BASKET</h3>
                    <p class="lamp-description"></p>
                    <p class="lamp-price">₦36,500</p>
                </div>
            </div>
            </router-link>
        </div>


    </div>


</template>

<script>
    export default {
        name: "ContactPage"
    }
</script>

<style scoped>

/* Hero Section */
.hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('@/assets/Pepps.jpg');
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero-text h1 {
  color: white;
  font-size: 48px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 30px;
  letter-spacing: 2px;
}

/* Write-Up Section */
.write-up {
  color: #333;
  font-size: 18px;
  padding: 40px 15%;
  line-height: 1.8;
  text-align: justify;
  background-color: #f9f9f9;
  border-left: 5px solid #ff5722;
}

/* Popular Products Section */
.popular {
  padding: 60px 20px;
  background-color: #fff;
  text-align: center;
}

.popular-title {
  color: #ff5722;
  font-size: 36px;
  margin-bottom: 40px;
  font-weight: bold;
}

.popular-lamp {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

.card {
    width: 250px; 
    margin: 20px; 
    background-color: #ffffff; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
    text-align: center;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
    border: 1px solid rgb(217, 217, 217);
    border-radius: 10px;
}

.card:hover {
    transform: scale(1.05); 
}

.card img {
    width: 250px;
    height: 250px; 
    object-fit: cover; 
    border-top-left-radius: 10px;
    border-top-right-radius:10px;
}
  
  
.lamp-details {
    color: black;
    text-align: left;
    padding: 10px;
}
  
.lamp-title {
    font-size: 20px;
    margin-bottom: 5px; 
    font-weight: 700;
}
  
.lamp-description {
    font-size: 14px; 
    margin-bottom: 10px; 
}
  
.lamp-price {
    font-weight: bold; 
    margin-bottom: 5px; 
    font-size: 20px;
}

.links {
    text-decoration: none;
}
</style>