<template>
    <div class="about-header">
        <h1>ABOUT US</h1>
    </div>
    <div class="about">
        <div class="text-container">
            <h2>We’re crafted with a bold bite!"</h2>
            <p>At DPS, 
                we believe that life is better with a little heat!
                Our journey began with a simple passion for peppers — from
                the sweet and mild to the fiery and bold. Today, we’re proud to 
                offer a carefully curated selection of the world’s finest peppers, grown 
                with care and chosen for their exceptional flavor and heat.
            </p>
        </div>
        <div class="about-content">
            <div class="image-container">
                <img src="../assets/manpep.jpg" alt="Man with light" width="600px" height="375px">
            </div>
            <div class="text-container">
                <h1>We have a professional Cultivating team</h1>
                <p>We work closely with trusted growers and suppliers 
                    to ensure that every pepper we sell meets our high standards for 
                    quality and freshness. From bell peppers to Carolina Reapers, our range 
                    offers something for everyone — no matter your heat tolerance.
                </p>
            </div>
        </div>
        <div class="text">
            <h1>“Bringing the heat with care – your satisfaction is our top priority, one pepper at a time!”</h1>
        </div>
        <div class="about-content">
            
            <!-- <div class="text-container">
                <h1>We value the ecosystem</h1>
                <p>We're making strides to lessen our environmental footprint.
                     That implies responsible retailing to us. 
                    To get there, we've come up with a set of targets. Such as eradicating excessive plastic from our product packaging and 
                    assuring that even the most ecologically impacting materials we use are supplied organically. 
                    Our LED lights do not contain mercury and therefore it is safe for the environment and can be replaced easily.
                </p>
            </div> 
            <div class="image-container">
                <img src="../assets/woman-light.jpg" alt="Man with light" width="600px" height="375px">
            </div>-->
        </div>
        <div class="about-content">
            <div class="image-container">
                <img src="../assets/pepper5.jpg" alt="holding pepper" width="600px" height="375px">
            </div>
            <div class="text-container">
                <h1>Our Commitment to You</h1>
                <p>At DPS, our dedication to you is as 
                    strong as the heat in our hottest peppers. We’re passionate 
                    about delivering not just the best peppers, but an exceptional experience 
                    that’s tailored to your taste. Whether you're a seasoned spice lover 
                    or just beginning to explore the world of peppers, we're here to ensure you 
                    get exactly what you need.
                </p>
                <p>From carefully sourcing the finest peppers to 
                    offering personalized support, we’re committed 
                    to your satisfaction every step of the way. 
                    Your pepper journey is our priority, and we’re always
                    here to help you spice things up!
                </p>
            </div>
        </div>
        <div class="about-content">
            <div class="text-container">
                <h1>Vision</h1>
                <p>
                   At DPS, our vision is to ignite the passion for peppers
                worldwide. We strive to be the ultimate destination for spice 
                lovers by offering the highest quality peppers that enhance every 
                culinary experience. Through our commitment to flavor, freshness, and fiery heat, we aim to 
                inspire kitchens everywhere, one pepper at a time.

                <!-- Our goal is to grow a community of pepper enthusiasts who embrace the bold 
                and the flavorful, bringing people together through their love of 
                spice. We envision a world where everyone can discover their perfect heat and 
                savor the unique taste of premium peppers.    -->
                </p>
            </div>
    
            <div class="text-container">
                <h1>Mission</h1>
                <p>
                   Our mission is to bring spice 
                   lovers like you the best peppers on the planet. 
                   Whether you’re looking to add a hint of sweetness to your 
                   favorite dish or challenge your taste buds with the hottest 
                   varieties out there, we’ve got you covered.
                </p>
            </div>
        </div>
        <br>
        <div class="text-container">
                <p>Explore our selection, find your perfect pepper, 
                    and let us help you turn up the heat in your kitchen. At DPS, we’re not just selling peppers — 
                    we’re sharing a love for flavor, spice, and all things bold.
                </p>
                 <h2>Welcome to the home of spice!</h2>
            </div>
    </div>
</template>

<script>
    export default {
        name: 'AboutPage',
    }
</script>

<style scoped>
   .about {
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
        color: black;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 20px;
    }
   .about-header {
        text-align: center;
        color: white;
        margin-bottom: 40px;
        margin-top: 70px;
        padding: 60px ;
        font-size: 60px;
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('@/assets/Fresh.jpg');
        background-size: contain;
        background-position: center;
        
    }
   .about-content {
        width: 100%;
        grid-template-columns: 1fr;
        grid-gap: 3rem;
        display: flex;
        padding: 40px 0px;
    }
   .image-container {
        flex-basis: 40%;
        margin: 20px;
    }
   .text-container {
        flex-basis: 50%;
        margin: 20px;
    }
   .image-container img {
        width: 600px;
        height: 425px;
    }
    .text h1{
        font-weight: 300;
    }
    .text-container h1 {
        font-weight: 300;
    }
    .text-container h3 {
        font-size: 30px;
    }
    .text-container p {
        font-size: 18px;
    }
</style>
