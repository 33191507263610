<template>
    <div>
        <div class="header">
            <div class="logo">
                <h1>D<span style="color: #ff5722;">P</span>S</h1>
            </div>
            <nav class="nav-links">
                <router-link to="/" class="links">Home</router-link>
                <router-link to="/products" class="links">Products</router-link>
                <router-link to="/about" class="links">About us</router-link>
                <router-link to="/contact" class="links">Contact us</router-link>
                <router-link to="/FAQ" class="links">FAQ</router-link>
            </nav>
            <div class="icons">
                <ul>
                    <li><i class="fas fa-magnifying-glass"></i></li>
                    <li><i class="fas fa-shop"></i></li>
                    <li><i class="fas fa-bars"></i></li>
                    
                </ul>
            </div>
        </div>
        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'HeaderPage',
    }

     window.addEventListener('scroll', function() {
    var navbar = document.querySelector('.header');
    
    if (window.scrollY > 50) { // When scroll is greater than 50px
      navbar.classList.add('scrolled');
    } else {
      navbar.classList.remove('scrolled');
    }
  });
</script>

<style scoped>
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: white;
        color:green;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: 0;
        padding: 0px 20px;
        width: 100%;
        z-index: 100;
        /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif; */
        font-family: 'Montserrat', sans-serif;
         transition: box-shadow 0.3s ease;
    }
    .header.scrolled {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow */
}
   
    .logo {
        color:green;
        cursor: pointer;
    }
    .links {
        color:black;
        text-decoration: none;
        font-size: 17px;
        padding: 0px 20px;
        
    }
    .links:hover{
        color: #ff5722;
        transition: 0.2s;
       
    }
    .icons ul {
        display: flex;
        list-style-type: none;
        padding-right: 30px;
        cursor: pointer;
    }
    .icons ul li i {
        font-size: 17px;
        padding: 7px 12px;
        transition: 0.2s;
        color: green;

    }

     .icons ul li i:hover{
        color: white;
        background-color:  green;
        border-radius: 5px;
        padding: 7px 12px;
      
     }
</style>
