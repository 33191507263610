<template>
  <div class="contact-head">
        <h1>CONTACT US</h1>
  </div>
  <div class="contact">
    <div class="contact-info">
    
      <div class="get-container">
        <h2>Reach Out</h2>
        <p>Have a question about our peppers or need help choosing the perfect spice 
          level? We're here to help! Reach out to us at DPS, and we'll make sure your pepper experience
          is as fiery or mild as you like!
          
        </p>
        <div class="contact-details">
          <ul>
            <li><i class="fa-solid fa-phone"></i> 08123456789</li>
            <li><i class="fa-brands fa-whatsapp"></i> +234 8123456789</li>
            <li><i class="fa-regular fa-envelope"></i> DPS@gmail.com</li>
            <li><i class="fa-solid fa-shop"></i> 123 Main Street, Lagos, Nigeria</li>
          </ul>
        </div>
      </div>

      <div class="form-container">
        <!-- <form class="contact-form">
          <div class="form-group">
            <label for="name">Name:</label>
            <input type="text" id="name" name="name" required>
          </div>
          <div class="form-group">
            <label for="email">Email:</label>
            <input type="email" id="email" name="email" required>
          </div>
          <div class="form-group">
            <label for="subject">Subject:</label>
            <input type="text" id="subject" name="subject" required>
          </div>
          <div class="form-group">
            <label for="message">Message:</label>
            <textarea id="message" name="message" rows="5" required></textarea>
          </div>
          <button type="submit">Send Now</button>
        </form>-->

        <form @submit.prevent="submitForm" class="contact-form">
      <div class="form-group">
        <label for="name">Full Name</label>
        <input type="text" id="name" v-model="formData.name" placeholder="Enter your full name" required />
      </div>

      <div class="form-group">
        <label for="email">Email Address</label>
        <input type="email" id="email" v-model="formData.email" placeholder="Enter your email address" required />
      </div>

      <div class="form-group">
        <label for="message">Your Message or Feedback</label>
        <textarea id="message" v-model="formData.message" placeholder="Write your message here" required></textarea>
      </div>

      <div class="form-group">
        <label for="feedbackType">Feedback Type</label>
        <select id="feedbackType" v-model="formData.feedbackType">
          <option value="general">General Inquiry</option>
          <option value="feedback">Feedback</option>
          <option value="support">Support</option>
        </select>
      </div>

      <button type="submit">Send Message</button>
      <br><br>
    </form>
      </div>
    </div> 
  </div>
</template>

<script>

export default {
  name: 'ContactPage',
  data() {
    return {
      formData: {
        name: '',
        email: '',
        message: '',
        feedbackType: 'general'
      }
    };
  },
  methods: {
    submitForm() {
      // Handle form submission (e.g., send to API or display a success message)
      console.log('Form submitted:', this.formData);
      alert('Thank you for your message! We will get back to you soon.');
      // Reset form
      this.formData = {
        name: '',
        email: '',
        message: '',
        feedbackType: 'general'
      };
    }
  }
};

</script>


<style scoped>

.contact-head {
  text-align: center;
  color: white;
  margin-bottom: 20px;
  padding: 70px 0px;
  font-size: 50px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('@/assets/cup.jpg');
  background-size: cover;
  background-position: center;
}

.contact{
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

.contact-info {
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
  display: flex;
  padding: 40px 0px;
}

.get-container {
    flex-basis: 40%;
    margin: 20px;
    color: black;
}

.contact-details ul{
    list-style: none;
    padding-left: 0px;
    text-align: left;
    font-size: 20px;
}

.contact-details ul li {
    padding: 10px 0px;
    color: black;
    cursor: pointer;
}

.contact-details i:hover ,ul li:hover {
    color: rgb(176, 174, 174);
}

.form-container {
    flex-basis: 50%;
    margin: 20px;
    border: 1px solid;
    border-radius: 8px;
   padding-right:50px ;
   padding-left: 25px;
   padding-top: 20px;
   
}








/* NEW FFORM DESIGN */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input, textarea, select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

textarea {
  resize: vertical;
  height: 150px;
}

button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
}

button:hover {
  background-color: #45a049;
}
</style>
